import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { SystemIcon, AccentIcon, AppletIcon } from '@workday/canvas-kit-react/icon';
import { sparkleSingleSmallIcon, searchSparkleIcon, documentSparkleIcon, editSparkleIcon, uploadIcon, moonIcon, noConnectionCloudIcon, thumbsDownFilledIcon, thumbsDownOutlinedIcon, thumbsUpFilledIcon, thumbsUpOutlinedIcon, filterExclamationIcon, referenceLineIcon, peopleRecruitingIcon, infoSparkleIcon, laurelWreathIcon, onboardingHomeIcon } from '@workday/canvas-system-icons-web';
import { sparkleIcon, candidateEngagementIcon, educationFinanceIcon, helpIcon, minusCircleIcon, mountainFlagIcon, notificationIcon, plusCircleIcon, scrollShieldIcon, skillsIcon, videoPlaylistIcon, workdayByokIcon } from '@workday/canvas-accent-icons-web';
import { candidateEngagementIcon as candidateEngagementAppletIcon, careerHubIcon as careerHubAppletIcon, extendIcon as extendAppletIcon, flowWriterIcon as flowWriterAppletIcon, guidedRequisitionIcon as guidedRequisitionAppletIcon, journeyIcon as journeyAppletIcon, managerInsightsIcon as managerInsightsAppletIcon, peopleWorkspaceIcon as peopleWorkspaceAppletIcon, planningIcon as planningAppletIcon, removeParInventoryIcon as removeParInventoryAppletIcon, userCartHubIcon as userCartHubAppletIcon, workdayByokIcon as workdayByokAppletIcon, jobReqWorkspaceIcon as jobReqWorkspaceAppletIcon } from '@workday/canvas-applet-icons-web';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "august-2024-design-asset-release"
      }}>{`August 2024 Design Asset Release`}</h2>
      <p>{`The August Design Asset Release is now available for use in the Canvas Assets Figma library for
designers, and for developers on npm, Artifactory, or SAS (in all environments). This release
includes one updated and three new system icons as well as a fix to several applet icons.`}</p>
      <p>{`The Canvas Assets Figma library has been updated to include these new additions. Developers will not
be impacted by this release. Designers are encouraged to upgrade Canvas Asset Figma library to the
latest version as there will be no impact to their existing designs.`}</p>
      <h3 {...{
        "id": "new--updated-system-icons"
      }}>{`New & Updated System Icons`}</h3>
      <p>{`We released three new system icons and updated the `}<inlineCode parentName="p">{`onboarding-home`}</inlineCode>{` icon to match their product
rebrand. To use these icons please upgrade to `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web@3.0.28`}</inlineCode>{`.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={peopleRecruitingIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-people-recruiting`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`peopleRecruitingIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={infoSparkleIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-info-sparkle`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`infoSparkleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={laurelWreathIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-laurel-wreath`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`laurelWreathIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={onboardingHomeIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-onboarding-home`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`onboardingHomeIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated`}</td>
          </tr>
        </tbody>
      </table>
      <h4 {...{
        "id": "fixed-applet-icons"
      }}>{`Fixed Applet Icons`}</h4>
      <p>{`Several of our applet icons were not applying colors correctly due to missing CSS class names on
their path elements. We updated these icons, and they now apply colors correctly. This is not a
visual breaking change, but it could cause diffs for SVG elements and cause some tests to fail. If
you see class names added to the applet icons in the table below in your tests after upgrading, you
can safely accept those changes. To uptake these fixes, please upgrade to
`}<inlineCode parentName="p">{`@workday/canvas-applet-icons-web@2.0.15`}</inlineCode>{`.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={candidateEngagementAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-candidate-engagement.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`candidateEngagementIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={careerHubAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-career-hub.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`careerHubIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={extendAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-extend.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`extendIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={flowWriterAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-flow-writer.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`flowWriterIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={guidedRequisitionAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-guided-requisition.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`guidedRequisitionIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={journeyAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-journey.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`journeyIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={managerInsightsAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-manager-insights.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`managerInsightsIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={peopleWorkspaceAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-people-workspace.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`peopleWorkspaceIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={planningAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-planning.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`planningIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={removeParInventoryAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-remove-par inventory.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`removeParInventoryIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={userCartHubAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-user-cart hub.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`userCartHubIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={workdayByokAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-workday-byok.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`workdayByokIcon`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AppletIcon size={48} icon={jobReqWorkspaceAppletIcon} mdxType="AppletIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-applet-job-req workspace.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`jobReqWorkspaceIcon`}</inlineCode></td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <h2 {...{
      "id": "canvas-1110-release"
    }}>{`Canvas 11.1.0 Release`}</h2>
    <InternalContent mdxType="InternalContent">
      <p>{`The Canvas 11.1.0 release is now available in Canvas Kit React and the Canvas Web v11 Figma library.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`The Canvas 11.1.0 release is now available in Canvas Kit React.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "new-divider-component"
    }}>{`New Divider Component`}</h3>
    <p>{`A new Divider component has been added to Canvas Kit Preview React. This component can be used as a generic horizontal rule to divide page-level information or as a decorative element to visually separate and organize content. The Divider component is now available in `}<inlineCode parentName="p">{`@workday/canvas-kit-preview-react@11.1.0`}</inlineCode>{`. `}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This cannot be represented in Figma as a standalone component, but designers can utilize the horizontal divider available in the Canvas Tokens v2 Figma library. Please note that only the horizontal version is currently available in code.`}</p>
      <h3 {...{
        "id": "modal--dialog-breakpoint-adjustments"
      }}>{`Modal & Dialog Breakpoint Adjustments`}</h3>
      <p>{`The prop names used in Modal and Dialog have been updated to match the name of Canvas breakpoint token. The following changes were made:`}</p>
      <ul>
        <li parentName="ul">{`Prop names updated from “Large Screen” to “Large” and “Small Screen” to “Small”`}</li>
        <li parentName="ul">{`The Small prop variations now use auto layout instead of frames, fixing previous resizing issues `}</li>
        <li parentName="ul">{`Both breakpoint tokens now use min and max-width
This will enable Modal and Dialog to better support responsive breakpoint stylings and align more closely with code. There will be no impact to existing designs. This update is specific to Figma and will not impact developers.
`}</li>
      </ul>
      <h3 {...{
        "id": "fix-for-persistent-button-color-in-modal"
      }}>{`Fix for Persistent Button Color in Modal`}</h3>
      <p>{`When using the Modal component in the Canvas Web v11 Figma library, switching from Primary to Delete Button types will result in the original button color persisting and over to the new button type. A bug fix has been applied to remove the persistent color issue. The correct color styles will now be applied when swapping between various button types.`}</p>
      <p>{`This bug fix will not impact existing designs that have been overridden to fix the issue. Developers will also not be impacted as this bug fix is specific to Canvas Figma libraries.`}</p>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "july-2024-design-asset-release"
      }}>{`July 2024 Design Asset Release`}</h2>
      <p>{`The July Design Asset Release is now available for use in the Canvas Assets Figma library for
designers, and for developers on npm, Artifactory, or SAS (in all environments). This release
includes one new system icon.`}</p>
      <p>{`The Canvas Assets Figma library has been updated to include these new additions. Developers will not
be impacted by this release. Designers are encouraged to upgrade Canvas Asset Figma library to the
latest version as there will be no impact to their existing designs.`}</p>
      <h3 {...{
        "id": "new-system-icon"
      }}>{`New System Icon`}</h3>
      <p>{`We released a new `}<inlineCode parentName="p">{`referenceLineIcon`}</inlineCode>{` for chart visualizations.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={referenceLineIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-reference-line.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`referenceLineIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "june-2024-design-asset-release"
      }}>{`June 2024 Design Asset Release`}</h2>
      <p>{`The June Design Asset Release is now available for use in the Canvas Assets Figma library for
designers, and for developers on npm, Artifactory, or SAS (in all environments). This release
includes twelve new system icons and one new accent icon. We also fixed several accent icons which
were not properly applying colors for theming.`}</p>
      <p>{`The Canvas Assets Figma library has been updated to include these new additions. Developers will not
be impacted by this release. Designers are encouraged to upgrade Canvas Asset Figma library to the
latest version as there will be minimal impact to their existing designs.`}</p>
      <h3 {...{
        "id": "new-system-icons"
      }}>{`New System Icons`}</h3>
      <p>{`We released four new AI system icons and eight other system icons, which are listed in the tables
below. To use these new icons, please upgrade to `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web@3.0.26`}</inlineCode>{`.`}</p>
      <h4 {...{
        "id": "ai-system-icons"
      }}>{`AI System Icons`}</h4>
      <p>{`We added these AI system icons to support AI-related actions.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={sparkleSingleSmallIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-sparkle-single-small.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`sparkleSingleSmallIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={searchSparkleIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-search-sparkle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`searchSparkleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={documentSparkleIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-document-sparkle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`documentSparkleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={editSparkleIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-edit-sparkle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`editSparkleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
        </tbody>
      </table>
      <h4 {...{
        "id": "other-system-icons"
      }}>{`Other System Icons`}</h4>
      <p>{`We added these system icons to support other generic actions.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={filterExclamationIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-filter-exclamation.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`filterExclamationIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={moonIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-moon.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`moonIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={noConnectionCloudIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-no-connection-cloud.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`noConnectionCloudIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={thumbsDownFilledIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-thumbs-down-filled.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`thumbsDownFilledIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={thumbsDownOutlinedIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-thumbs-down-outlined.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`thumbsDownOutlinedIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={thumbsUpFilledIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-thumbs-up-filled.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`thumbsUpFilledIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={thumbsUpOutlinedIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-thumbs-up-outlined.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`thumbsUpOutlinedIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><SystemIcon icon={uploadIcon} mdxType="SystemIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-icon-upload.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`uploadIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "accent-icons"
      }}>{`Accent Icons`}</h3>
      <p>{`We added one new sparkle accent icon and fixed accent icons that were not properly applying colors
for theming. To use this new icon and the corrected icons, please upgrade to
`}<inlineCode parentName="p">{`@workday/canvas-accent-icons-web@3.0.14`}</inlineCode>{`.`}</p>
      <h4 {...{
        "id": "new-ai-sparkle-accent-icon"
      }}>{`New AI Sparkle Accent Icon`}</h4>
      <p>{`We released a new accent sparkle icon for AI-related tasks.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={sparkleIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-sparkle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`sparkleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New`}</td>
          </tr>
        </tbody>
      </table>
      <h4 {...{
        "id": "fixed-accent-icons"
      }}>{`Fixed Accent Icons`}</h4>
      <p>{`Several of our accent icons were not applying colors correctly due to missing CSS class names on
their path elements. We updated these icons, and they now apply colors correctly. This is not a
visual breaking change, but it could cause diffs for SVG elements and cause some tests to fail. If
you see class names added to the accent icons listed below in your tests after upgrading, you can
safely accept those changes.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Preview`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`File Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Icon Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Status`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={candidateEngagementIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-candidate-engagement.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`candidateEngagementIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={educationFinanceIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-education-finance.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`educationFinanceIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={helpIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-help.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`helpIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={minusCircleIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-minus-circle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`minusCircleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={mountainFlagIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-mountain-flag.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`mountainFlagIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={notificationIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-notification.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`notificationIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={plusCircleIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-plus-circle.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`plusCircleIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={scrollShieldIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-scroll-shield.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`scrollShieldIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={skillsIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-skills.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`skillsIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={videoPlaylistIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-video-playlist.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`videoPlaylistIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><AccentIcon icon={workdayByokIcon} mdxType="AccentIcon" /></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`wd-accent-wd-accent-workday-byok.svg`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`workdayByokIcon`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Fixed`}</td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-mobile-2024-q1-release"
      }}>{`Canvas Mobile 2024 Q1 Release`}</h2>
      <p>{`Our Canvas Mobile Q1 release brings a big punch! Available `}<strong parentName="p">{`May 17, 2024,`}</strong>{` this release offers
exciting new components, highly anticipated enhancements, and updated documentation. There is
minimum to no impact for designers and developers.`}</p>
      <h3 {...{
        "id": "release-overview"
      }}>{`Release Overview`}</h3>
      <p>{`View the table below which highlights the updates that will be rolled out as part of the release.
The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade.`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Mobile Components In Production`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Top Sheet, Switch, Badges, Snackbar have been added to the production Figma library, `}<a parentName="td" {...{
                "href": "https://www.figma.com/design/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?m=auto&node-id=8625%3A7006&t=xp62XCDp6ZTwtmFA-1"
              }}>{`Canvas Mobile`}</a>{`.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Action Bar Enhancements`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Enhancements to the menu in Action Bar are now available.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New and Updated Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New and updated design guidelines and developer documentation is available for several components - see full list below with direct links to the documentation.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "new-mobile-components-in-production"
      }}>{`New Mobile Components In Production`}</h3>
      <p>{`The following components are available now in the
`}<a parentName="p" {...{
          "href": "https://www.figma.com/design/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?m=auto&node-id=8625%3A7006&t=xp62XCDp6ZTwtmFA-1"
        }}>{`Canvas Mobile Figma Library`}</a>{`:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Top Sheet:`}</strong>{` Keeps scoped tasks stemming from the Top App Bar contextually relevant to a parent
screen.`}</li>
        <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` Toggles a function on or off via a selection control.`}</li>
        <li parentName="ul"><strong parentName="li">{`Badges:`}</strong>{` Indicates items associated with a parent element.`}</li>
        <li parentName="ul"><strong parentName="li">{`Snackbar:`}</strong>{` Informs users about a process that an app has performed or will perform via a popup`}</li>
      </ul>
      <h3 {...{
        "id": "action-bar-enhancements"
      }}>{`Action Bar Enhancements`}</h3>
      <p>{`Action Bar will now include the Canvas approved `}<a parentName="p" {...{
          "href": "/components/popups/menu/#tab=ios"
        }}>{`Menu`}</a>{` instead of
native iOS and Android menus.`}</p>
      <h3 {...{
        "id": "new-and-updated-documentation"
      }}>{`New and Updated Documentation`}</h3>
      <p>{`For guidelines on how to use these components, go to the documentation:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/navigation/bottom-navigation-bar#tab=ios"
          }}><strong parentName="a">{`Bottom Nav Bar`}</strong></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/navigation/top-app-bar#tab=ios"
          }}><strong parentName="a">{`Top App Bar`}</strong></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/popups/top-sheet#tab=ios"
          }}><strong parentName="a">{`Top Sheet`}</strong></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/indicators/badge#tab=ios"
          }}><strong parentName="a">{`Badges`}</strong></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/inputs/switch#tab=ios"
          }}><strong parentName="a">{`Switch`}</strong></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/popups/snackbar#tab=ios"
          }}><strong parentName="a">{`Snackbar`}</strong></a></li>
      </ul>
      <p>{`Design usage guidance coming soon! Dev documentation is now available for the following components:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/layout/carousel#tab=ios"
          }}><strong parentName="a">{`Carousel`}</strong></a></li>
      </ul>
    </InternalContent>
    <h2 {...{
      "id": "canvas-v11-release"
    }}>{`Canvas v11 Release`}</h2>
    <InternalContent mdxType="InternalContent">
      <p>{`Canvas v11 will be released on `}<strong parentName="p">{`May 22nd, 2024`}</strong>{` with updates to Canvas Kit and the Canvas Figma
libraries. An upgrade guide will be available once v11 goes live to assist product teams with
upgrading to the newest version of Canvas.`}</p>
      <p>{`The v11 release will include the introduction of color system tokens, the return of CSS, and newly
refactored components!`}</p>
      <h3 {...{
        "id": "release-overview-1"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v11 release. The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v11 and/or switch to the
new v11 Canvas Web Figma library`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Color System Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new set of semantic color system tokens will be released.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated Depth and Opacity Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth and opacity tokens will be updated to use the new color system tokens.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Breakpoint Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New breakpoint tokens will be provided to help teams apply responsive styles.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`The Return of Canvas Kit CSS`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit CSS is making a comeback! As components are restyled in Canvas Kit React, they will be made available in Canvas Kit CSS.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Table (Preview) Refactor & Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Table component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables and promoted, replacing the Table in the Main package.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Components Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Svg, System Icon, System Icon Circle, Accent Icon, Applet Icon, and Graphic icon components will all be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Form Field (Preview) Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Form Field (Preview) will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables, making it available for use with the current input components in Main.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Text Components Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Text components will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Status Indicator (Preview) Refactor & Model Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Status Indicator component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes. Models used in Status Indicator will also be removed.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Button Token Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new color system tokens. Fixes will also be applied that will result in minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Radio (Preview) Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Radio component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Switch Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Switch will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Card Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Card will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Select Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Select will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Count Badge Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Count Badge will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Text Input (Preview), Text Area (Preview), Form Field (Main), and Label Text (Main) will all be deprecated in v11.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
 </InternalContent>
 <ExternalContent mdxType="ExternalContent">
      <p>{`Canvas v11 will be released on `}<strong parentName="p">{`May 22nd, 2024`}</strong>{` with updates to Canvas Kit. An upgrade guide will
be available once v11 goes live to assist product teams with upgrading to the newest version of
Canvas.`}</p>
      <p>{`The v11 release will include the introduction of color system tokens, the return of CSS, and newly
refactored components!`}</p>
      <h3 {...{
        "id": "release-overview-2"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v11 release. The impact for developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v11`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Color System Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new set of semantic color system tokens will be released.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated Depth and Opacity Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth and opacity tokens will be updated to use the new color system tokens.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Breakpoint Tokens`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New breakpoint tokens will be provided to help teams apply responsive styles.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`The Return of Canvas Kit CSS`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit CSS is making a comeback! As components are restyled in Canvas Kit React, they will be made available in Canvas Kit CSS.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Table (Preview) Refactor & Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Table component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables and promoted, replacing the Table in the Main package.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Components Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Svg, System Icon, System Icon Circle, Accent Icon, Applet Icon, and Graphic icon components will all be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Form Field (Preview) Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Form Field (Preview) will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables, making it available for use with the current input components in Main.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Text Components Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Text components will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Status Indicator (Preview) Refactor & Model Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Status Indicator component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes. Models used in Status Indicator will also be removed.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Button Token Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new color system tokens. Fixes will also be applied that will result in minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Radio (Preview) Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Radio component in Preview will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Switch Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Switch will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables with some minor visual changes.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Card Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Card will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Select Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Select will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Count Badge Refactor`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Count Badge will be refactored to use the new `}<inlineCode parentName="td">{`cs`}</inlineCode>{` prop and CSS variables.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Text Input (Preview), Text Area (Preview), Form Field (Main), and Label Text (Main) will all be deprecated in v11.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
 </ExternalContent>
    <h3 {...{
      "id": "new-color-system-tokens"
    }}>{`New Color System Tokens`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8f60716ce3df4e395b507f5cfb0ce1dc/3534c/v11-comms-color-system-tokens.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.270270270270274%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR42n2R6w6DIAyFff/nnJnOG+gYN+GsLZDoj+0kxzTYfuVoB1JKCdY5OO/FXL+NEbMyiuJ5wtrSZ63Fh2zMByfNN3X8OKlxUxrq4mEY0fe9LMu5IBmwrBv0fkDpHdum8OifsuAG/CW905BSCCHIjXjxNU2MUc6vugE5Ejd6Argam2sBkFNO8AyivgZvCVqKG5C/B0fi+POyUixNQC+QECL245DzaV6qZ4yvSWpfY/+NfJVEpR/C5mFXb11u7+U9A7+NRdWex1yaQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image displaying how diffrent color system tokens can be used to create the Text Input component",
            "title": "Image displaying how diffrent color system tokens can be used to create the Text Input component",
            "src": "/static/8f60716ce3df4e395b507f5cfb0ce1dc/50383/v11-comms-color-system-tokens.png",
            "srcSet": ["/static/8f60716ce3df4e395b507f5cfb0ce1dc/1efb2/v11-comms-color-system-tokens.png 370w", "/static/8f60716ce3df4e395b507f5cfb0ce1dc/50383/v11-comms-color-system-tokens.png 740w", "/static/8f60716ce3df4e395b507f5cfb0ce1dc/3534c/v11-comms-color-system-tokens.png 808w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Following the release of the restructured Canvas tokens in v10, a new set of color system tokens
will be added to the Canvas Tokens Web package. Like the other sets of system tokens released in
v10, color system tokens will be semantic to help teams understand how to use and apply these tokens
to their product.`}</p>
    <p>{`Color system tokens will live alongside brand tokens, but with the following distinctions: Brand
tokens should only be used when tenant branding is possible / allowed. The color that brand tokens
display will be dependent on the tenant configuration. System tokens should be used in cases where
tenant branding is not possible / allowed. This prevents tenant configuration from altering the
color used in a component. System tokens are designed to make theming (non-tenant branding) easier
in the future. We advise using System tokens over Base tokens whenever possible.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`Since they are a net-new add, color system tokens will not impact developers or designers. However,
teams will begin seeing color system tokens rolled out to Canvas components.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Since they are a net-new add, color system tokens will not impact developers. However, teams will
begin seeing color system tokens rolled out to Canvas components.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "updated-depth-and-opacity-tokens"
    }}>{`Updated Depth and Opacity Tokens`}</h3>
    <p>{`Depth and opacity system tokens will be updated to use the new color system tokens. Tying color
system tokens to depth and opacity tokens will make it easier to roll out color changes to
color-dependent Canvas tokens. This update will not impact the actual color rendered for depth and
opacity as the color system tokens utilized for this change references the original base color
tokens that were being used.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`As a result, this will not impact developers or designers.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`As a result, this will not impact developers.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "new-breakpoint-system-tokens"
      }}>{`New Breakpoint System Tokens`}</h3>
      <p>{`New breakpoint system tokens will be available with v11! These tokens will allow developers and
designers to have a single source of truth for breakpoints. They’ll also make it easier for teams to
apply responsive styles to components, opening the door for more responsive style customizations.`}</p>
      <p>{`Breakpoint variables can be set to auto-layout frames in Figma. Designers can set min and max widths
of artboards to Canvas breakpoint variables to create responsive designs. The new responsive grid
style will also behave more closely to the Canvas Grid component and css grids.`}</p>
      <p>{`This is a net-new feature so it will not impact developers or designers. However, it is recommended
for developers to begin migrating over to use these new breakpoint system tokens instead of the
breakpoints in the Emotion theme object from CanvasProvider. More information will be available in
the v11 upgrade guide to help developers adopt breakpoint tokens.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "new-breakpoint-system-tokens-1"
      }}>{`New Breakpoint System Tokens`}</h3>
      <p>{`New breakpoint system tokens will be available with v11! These tokens will allow developers to have
a single source of truth for breakpoints. They’ll also make it easier for teams to apply responsive
styles to components, opening the door for more responsive style customizations.`}</p>
      <p>{`This is a net-new feature so it will not impact developers. However, it is recommended for
developers to begin migrating over to use these new breakpoint system tokens instead of the
breakpoints in the Emotion theme object from CanvasProvider. More information will be available in
the v11 upgrade guide to help developers adopt breakpoint tokens.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "the-return-of-canvas-kit-css"
    }}>{`The Return of Canvas Kit CSS`}</h3>
    <p>{`Over the past few years, Canvas Kit React has seen major improvements while Canvas Kit CSS
development was paused and set into maintenance mode. This was primarily due to the cost of
maintaining two implementations of Canvas and trying to keep them in sync. But with the v11 release,
Canvas Kit CSS is officially making a comeback and taking center stage!`}</p>
    <p>{`As Canvas Kit React components are being refactored to use the new styling utilities and CSS tokens,
Canvas Kit CSS will also begin building off Canvas Kit React. This allows both Canvas Kit CSS and
React to be updated in sync, reducing maintenance costs and improving support for teams running on
CSS.`}</p>
    <p>{`Since v5, Canvas Kit CSS has been in maintenance mode and has not been updated. Teams upgrading to
the Canvas Kit CSS v11 should expect major breaking changes that help bring CSS components into
alignment with what’s available in Canvas Kit React. Not all components available in previous
versions of Canvas Kit CSS will be available for v11. Components will be added to Canvas Kit CSS as
they are being refactored in Canvas Kit React. However, consumers can expect new Labs and Preview
packages that will align with Canvas Kit React’s offerings.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is developer specific and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "table-preview-refactor--promotion"
    }}>{`Table (Preview) Refactor & Promotion`}</h3>
    <p>{`The compound Table component in Preview will be updated to use Canvas Tokens and the new styling
utilities. The component will also be modified to support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop. Since the outdated Table
component in Main was deprecated in v10, the updated Table component in Preview will also be
promoted to replace the one in Main.`}</p>
    <p>{`Developers will need to update their usage of Table to reflect the new API. More guidance will be
provided in the v11 upgrade guides to help developers uptake this change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "icon-components-refactor"
    }}>{`Icon Components Refactor`}</h3>
    <p>{`All icons components will be refactored to use the new Canvas tokens and styling utilities. These
refactored icon components will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, `}<strong parentName="p">{`styles prop will be removed`}</strong>{`. The
following icon components will be updated as part of this work: Svg, System Icon, System Icon
Circle, Accent Icon, Applet Icon, and Graphic.`}</p>
    <p>{`There are no changes to the behavior or functionality of Svg or System Icon Circle. However, the
following changes will be made to the remaining icon components:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`System Icon:`}</strong>{` `}<inlineCode parentName="li">{`SystemIconStyles`}</inlineCode>{` prop will be deprecated and merged with `}<inlineCode parentName="li">{`SystemIconProps`}</inlineCode>{` in a
future version. `}<inlineCode parentName="li">{`accentHover`}</inlineCode>{`, `}<inlineCode parentName="li">{`backgroundHover`}</inlineCode>{`, `}<inlineCode parentName="li">{`fillHover`}</inlineCode>{`, `}<inlineCode parentName="li">{`fill`}</inlineCode>{`, and `}<inlineCode parentName="li">{`systemIconStyles`}</inlineCode>{` will
be deprecated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accent Icon:`}</strong>{` `}<inlineCode parentName="li">{`AccentIconStyles`}</inlineCode>{` prop will be deprecated and will be merged with
`}<inlineCode parentName="li">{`AccentIconProps`}</inlineCode>{` in a future version. `}<inlineCode parentName="li">{`accentIconStyles`}</inlineCode>{` will also be deprecated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Applet Icon:`}</strong>{` `}<inlineCode parentName="li">{`AppletIconStyles`}</inlineCode>{` prop will be deprecated and merged with `}<inlineCode parentName="li">{`AppletIconProps`}</inlineCode>{` in a
future version. `}<inlineCode parentName="li">{`appletIconStyles`}</inlineCode>{` will also be deprecated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Graphic:`}</strong>{` `}<inlineCode parentName="li">{`GraphicStyles`}</inlineCode>{` prop will be deprecated and merged with `}<inlineCode parentName="li">{`GraphicProps`}</inlineCode>{` in a future
version. `}<inlineCode parentName="li">{`width`}</inlineCode>{` and `}<inlineCode parentName="li">{`height`}</inlineCode>{` props will also be deprecated.`}</li>
    </ul>
    <p>{`A codemod will be available to help developers uptake these changes and more guidance will be
provided in the v11 upgrade guide.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "form-field-refactor"
    }}>{`Form Field Refactor`}</h3>
    <p>{`The Form Field component in Preview will be refactored to use the new Canvas CSS tokens, styling
utilities, and the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop. These changes will allow Form Field in Preview to work with the
current input components in Main while providing more flexibility.`}</p>
    <p>{`As part of this refactoring work:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`orientation`}</inlineCode>{` prop will default to vertical and is no longer required`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`FormField.Input`}</inlineCode>{` will then be compatible with any of our inputs in Main`}</li>
      <li parentName="ul">{`The prop `}<inlineCode parentName="li">{`hasError`}</inlineCode>{` will be renamed to `}<inlineCode parentName="li">{`error`}</inlineCode>{` to accept the values:
`}<inlineCode parentName="li">{`"error" | "alert" | undefined`}</inlineCode>{` to provide support for the Text Input and Text Area component in
Main. A codemod will be available to rename this prop.`}</li>
    </ul>
    <p>{`Developers using the Form Field in Main should switch over to using the updated Form Field in
Preview. Please note that the Form Field component in Preview does not support the `}<inlineCode parentName="p">{`useFieldSet`}</inlineCode>{`
prop that the Form Field in Main does. In order to achieve the same behavior, set the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop on
the Form Field element to fieldset and the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop of `}<inlineCode parentName="p">{`FormField.Label`}</inlineCode>{` to legend.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to code and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "text-components-refactor"
    }}>{`Text Components Refactor`}</h3>
    <p>{`Text components will be refactored to use the new styling utilities and tokens. The Text component
will longer extend the Box component. However, it will still support Emotion `}<inlineCode parentName="p">{`styled`}</inlineCode>{` and style
props. The following text components have not been impacted since they extend the Text component:
Title, Heading, Body Text and Subtext. However, the Label Text component has been deprecated.`}</p>
    <p>{`Developers using Label Text should refer to the Component Deprecations section to learn more about
this upcoming change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "status-indicator-refactor--model-removal"
    }}>{`Status Indicator Refactor & Model Removal`}</h3>
    <p>{`The Status Indicator component will be refactored to use the new Canvas CSS tokens and styling
utilities. Status Indicator will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but the API will remain unchanged,
preserving its behavior and functionality.`}</p>
    <p>{`However, small visual changes will be made as a result of refactoring Status Indicator to use the
new color system tokens. These visual changes will align more closely with what’s available in
Figma.`}</p>
    <p>{`As part of the refactoring work, the following models will be removed in favor of stencil:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`useStatusIndicatorModel`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`useStatusIndicator`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`statusIndicatorColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`useStatusIndicatorIcon`}</inlineCode></li>
    </ul>
    <p>{`Developers may need to update their visual tests as a result of these changes. An upgrade guide will
be provided for v11 to help developers uptake these changes.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers`}</p>
    </InternalContent>
    <h3 {...{
      "id": "button-token-updates"
    }}>{`Button Token Updates`}</h3>
    <p>{`With the introduction of system color tokens in v11, Canvas buttons will be updated to utilize
system color tokens where applicable. Small bug fixes will also be made to ensure alignment between
design and code, which will result in small visual changes.`}</p>
    <p>{`Developers may need to update their visual tests as a result of these changes. An upgrade guide will
be provided for v11 to help developers uptake these changes.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "checkbox-refactor"
    }}>{`Checkbox Refactor`}</h3>
    <p>{`The Checkbox component will be refactored to use the new Canvas CSS tokens and styling utilities.
Checkbox will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but the API will remain unchanged, preserving its behavior
and functionality.`}</p>
    <p>{`However, small visual changes will be made as a result of refactoring Checkbox to use the new color
system tokens. These visual changes will align more closely with what’s available in Figma.`}</p>
    <p>{`Developers may need to update their visual tests as a result of these changes. An upgrade guide will
be provided for v11 to help developers uptake these changes.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "radio-preview-refactor"
    }}>{`Radio (Preview) Refactor`}</h3>
    <p>{`The Radio component in Preview will be refactored to use the new Canvas CSS tokens and styling
utilities. Radio will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but the API will remain unchanged, preserving its
behavior and functionality.`}</p>
    <p>{`However, small visual changes will be made as a result of refactoring Radio to use the new color
system tokens. These visual changes will align more closely with what’s available in Figma.
Additionally, a new inverse focus ring variant has been added to Figma to align with what’s
available in code`}</p>
    <p>{`Developers may need to update their visual tests as a result of these changes. An upgrade guide will
be provided for v11 to help developers uptake these changes.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "switch-refactor"
    }}>{`Switch Refactor`}</h3>
    <p>{`The Switch component will be refactored to use the new Canvas CSS tokens and styling utilities.
Switch will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but the API will remain unchanged, preserving its behavior
and functionality.`}</p>
    <p>{`However, small visual changes will be made as a result of refactoring Switch to use the new color
system tokens. These visual changes will align more closely with what’s available in Figma.`}</p>
    <p>{`Developers may need to update their visual tests as a result of these changes. An upgrade guide will
be provided for v11 to help developers uptake these changes.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "card-refactor"
    }}>{`Card Refactor`}</h3>
    <p>{`The Card component will be refactored to use the new Canvas CSS tokens and styling utilities. Card
will now support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but the API will remain unchanged, preserving its behavior and
functionality.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact developers or designers.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This update will not impact developers.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "select-refactor"
    }}>{`Select Refactor`}</h3>
    <p>{`The Select component will be refactored to use the new Canvas CSS tokens and styling utilities. The
component will support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but otherwise the API will remain unchanged. It will behave
identically as it did in previous versions.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact developers or designers.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This update will not impact developers.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "count-badge-refactor"
    }}>{`Count Badge Refactor`}</h3>
    <p>{`The Count Badge component will be refactored to use the new Canvas CSS tokens and styling utilities.
The component will support the `}<inlineCode parentName="p">{`cs`}</inlineCode>{` prop, but otherwise the API will remain unchanged. It will
behave identically as it did in previous versions.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact developers or designers.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This update will not impact developers.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "component-deprecations"
    }}>{`Component Deprecations`}</h3>
    <p>{`The following components will be deprecated as part of the v11 release:`}</p>
    <ul>
      <li parentName="ul">{`Text Input from Preview`}</li>
      <li parentName="ul">{`Text Area from Preview`}</li>
      <li parentName="ul">{`Form Field from Main`}</li>
      <li parentName="ul">{`LabelText from Main`}</li>
    </ul>
    <p>{`These components will still be available for use in Canvas v11, but deprecation warnings will appear
in the code editor when they are in use.`}</p>
    <p>{`Developers using the deprecated Text Input (Preview), Text Area (Preview), and FormField (Main)
components should replace them with the updated Form Field component in Preview, which can now
perform the same functionalities as these deprecated components. Teams using `}<inlineCode parentName="p">{`LabelText`}</inlineCode>{` should
switch to use the parent Text component or `}<inlineCode parentName="p">{`FormField.Label`}</inlineCode>{` from the Preview package (if using in
the context of a form element), which serves the same functionality as LabelText.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will not impact designers.`}</p>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "april-2024-design-asset-release"
      }}>{`April 2024 Design Asset Release`}</h2>
      <p>{`The April 2024 Design Asset Release is now available for use through the Canvas Assets Figma library
for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`This release includes new system icons and bug fixes.`}</p>
      <p>{`The following new and updated assets are now available:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.22`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-translated-actuals.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`translatedActualsIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`An updated `}<inlineCode parentName="li">{`wd-icon-instance.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`instanceIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) with 2px stroke for consistency`}</li>
            <li parentName="ul">{`An updated `}<inlineCode parentName="li">{`wd-icon-box-text-search.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`boxTextSearchIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) to make the magnifying glass more
distinct`}</li>
            <li parentName="ul">{`An updated `}<inlineCode parentName="li">{`wd-icon-chevron-hierarchy-closed.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`chevronHierarchyClosedIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) to unfill the circle elenent`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include this update. This release will not
impact developers and designers can upgrade to the latest version of the Canvas Asset Figma library
with minimal impact to their existing designs.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
      <h2 {...{
        "id": "april-2024-figma-library-bug-fixes"
      }}>{`April 2024 Figma Library Bug Fixes`}</h2>
      <p>{`The Canvas Web v10 Figma library was updated on `}<strong parentName="p">{`April 10th, 2024`}</strong>{` with the following bug fixes:`}</p>
      <ul>
        <li parentName="ul">{`Extra borders accidentally added to Tooltip and Dialog in v10 have been removed`}</li>
        <li parentName="ul">{`The frame for Toast has been updated to clip content in order to correctly display drop shadow and
prevent the corner edges of the frame from showing`}</li>
      </ul>
      <p>{`This bug fix will not impact existing designs and will not be rolled out to previous versions of the
Canvas Web Figma library. To uptake these bug fixes, please accept the updates in your Figma files.`}</p>
      <h2 {...{
        "id": "march-2024-design-asset-release"
      }}>{`March 2024 Design Asset Release`}</h2>
      <p>{`The March Design Assets release is now available`}</p>
      <p>{`This release contains a fix to the responsive behavior of Empty States in the Canvas Assets Figma
library. The fix ensures that the aspect ratios of Empty States will be maintained if they are
resized. Designs using Empty States that were resized using Figma's scale tool will not be impacted
by this update. However, Empty States resized through the shift and drag method may be altered with
this bug fix.`}</p>
      <p>{`This release will not impact developers, and designers can upgrade to the latest version of the
Canvas Asset Figma library with minimal impacts to their existing designs.`}</p>
      <h2 {...{
        "id": "february-2024-design-asset-release"
      }}>{`February 2024 Design Asset Release`}</h2>
      <p>{`The February 2024 Design Asset Release is now available for use through the Canvas Assets Figma
library for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`This release includes new system icons, bug fixes, and an icon removal.`}</p>
      <p>{`The following new assets are now available:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.21`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-chevron-hierarchy-closed.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`chevronHierarchyClosedIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-chevron-hierarchy-open.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`chevronHierarchyOpenIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-hierarchy-child.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`hierarchyChildIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-hierarchy-diagonal-parent-child.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`hierarchyDiagonalParentChildIcon`}</inlineCode>{`
when using `}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-hierarchy-diagonal-parent-child-child.svg`}</inlineCode>{` icon
(`}<inlineCode parentName="li">{`hierarchyDiagonalParentChildChildIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-hierarchy-diagonal-parent.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`hierarchyDiagonalParentIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-fan-chart.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`fanChartIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The following icon will be removed:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`setting`}</inlineCode>{` Applet icon in the Canvas Assets Figma library. This icon was accidentally released in
Figma, but has not been available in code.`}</li>
      </ul>
      <p>{`The following bug fixes were applied part of this release:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.20`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul">{`Fixed alignment and added in accent and background layers for `}<inlineCode parentName="li">{`wd-icon-hierarchy.svg`}</inlineCode>{`
(`}<inlineCode parentName="li">{`hierarchyIcon`}</inlineCode>{` when using `}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`Fixed alignment and added in accent and background layers for `}<inlineCode parentName="li">{`wd-icon-hierarchy-full.svg`}</inlineCode>{`
(`}<inlineCode parentName="li">{`hierarchyFullIcon`}</inlineCode>{` when using `}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`Added accent and background layers to `}<inlineCode parentName="li">{`wd-icon-hierarchy-parent-child.svg`}</inlineCode>{`
(`}<inlineCode parentName="li">{`hierarchyParentChildIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`Added accent and background layers to `}<inlineCode parentName="li">{`wd-icon-hierarchy-parent.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`hierarchyParentIcon`}</inlineCode>{` when
using `}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`Added accent and background layers to `}<inlineCode parentName="li">{`wd-icon-hierarchy-right-full.svg`}</inlineCode>{`
(`}<inlineCode parentName="li">{`hierarchyRightFullIcon`}</inlineCode>{` when using `}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Improved responsive behavior for all Applet icons in the Canvas Assets Figma library to ensure
that their aspect ratios are maintained when being resized. Designs using icons that were resized
using Figma's scale tool will not be impacted by this update. However, icons resized through the
shift and drag method may be altered with this bug fix.`}</li>
      </ul>
      <p>{`This release will not impact developers, and designers can upgrade to the latest version of the
Canvas Asset Figma library with minimal impacts to their existing designs. For more information or
to access these new icons in this update, please reference the Design Asset repo.`}</p>
      <h2 {...{
        "id": "canvas-mobile-q4-release"
      }}>{`Canvas Mobile Q4 Release`}</h2>
      <p>{`The Canvas Mobile Q4 release will be available on `}<strong parentName="p">{`February 14th, 2024.`}</strong>{` This release includes new
components and enhancements as well as documentation and site updates.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New components being added to the Canvas Mobile Figma beta library include: Top App Bar, Top Sheet, Carousel.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Enhancements`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Enhancements to existing components being added into the Canvas Mobile library include: filter capabilities for Pills, selection states for Menu, dividers for Expandable, and a reorganized text hierarchy.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated documentation will be released for List Item.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Taxonomy Changes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A number of mobile patterns are being reclassified as components: Read Only, Prompt, BP Conclusion, Progressive Grid.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "new-mobile-components"
      }}>{`New Mobile Components`}</h3>
      <p>{`The following new components will be available in the Canvas Mobile Figma beta library:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Top App Bar:`}</strong>{` An app bar that orients titles, global navigation, and local page actions as well
as additional functionalities such as search, banners, and selection mode at the top of full
screen views.`}</li>
        <li parentName="ul"><strong parentName="li">{`Top Sheet:`}</strong>{` A popup emerging from the Top App Bar that contains a scoped task related to the
parent screen.`}</li>
        <li parentName="ul"><strong parentName="li">{`Carousel:`}</strong>{` A layout that enables horizontal navigation to view overflowing content placed
inside the component.`}</li>
      </ul>
      <p>{`The following new component will be available in the Canvas Mobile Figma library:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Hyperlink (Android):`}</strong>{` A button for external redirection outside of the app.`}</li>
      </ul>
      <p>{`These updates will not impact designers or developers, as they are net-new components.`}</p>
      <h3 {...{
        "id": "mobile-component-enhancements"
      }}>{`Mobile Component Enhancements`}</h3>
      <p>{`The following component enhancements will be available in the Canvas Mobile Figma library:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Selection States for Menu`}</strong>{` Added selection states to provide users with improved signposting
within menus.`}</li>
        <li parentName="ul"><strong parentName="li">{`Filter Pills`}</strong>{` Added filtering functionality to Pills.`}</li>
        <li parentName="ul"><strong parentName="li">{`Expandable`}</strong>{` Divider property added in.`}</li>
        <li parentName="ul"><strong parentName="li">{`Text`}</strong>{` Separated into levels of hierarchy.`}</li>
      </ul>
      <p>{`These updates will not impact designers or developers, as they are net new variations to existing
components or changes in underlying organization.`}</p>
      <h3 {...{
        "id": "updated-documentation"
      }}>{`Updated Documentation`}</h3>
      <p>{`Documentation will be available for all the new components and enhancements released into the Canvas
Mobile Figma Library as part of this update. Components released into the Canvas Mobile Figma beta
Library will not be accompanied with documentation until the Q1 release. Please check refer to Figma
specs or reach out to the designer with questions in the meantime.`}</p>
      <p>{`Outside of the updates above, mobile documentation on the Canvas Design System site has been updated
for List Item. This update will not impact designers or developers, as it is specific to Canvas
documentation.`}</p>
      <h3 {...{
        "id": "taxonomy-changes"
      }}>{`Taxonomy Changes`}</h3>
      <p>{`Read Only, Prompt, BP Conclusion, Progressive Grid have been reclassified in their taxonomy from
patterns to components. This update will not impact designers or developers, as it is specific to
Canvas documentation.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "canvas-ai-elements-release"
    }}>{`Canvas AI Elements Release`}</h2>
    <p>{`New AI elements are now available through Canvas! These elements are meant to be used for AI
features and products`}</p>
    <p>{`Coming soon...`}</p>
    <h2 {...{
      "id": "previous-releases"
    }}>{`Previous Releases`}</h2>
    <InternalContent mdxType="InternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v10-announcements"
          }}>{`Canvas v10 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v9-announcements"
          }}>{`Canvas v9 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v8-announcements"
          }}>{`Canvas v8 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v7-announcements"
          }}>{`Canvas v7 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v6-announcements"
          }}>{`Canvas v6 Announcements`}</a></li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v10-announcements"
          }}>{`Canvas v10 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v9-announcements"
          }}>{`Canvas v9 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v8-announcements"
          }}>{`Canvas v8 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v7-announcements"
          }}>{`Canvas v7 Announcements`}</a></li>
      </ul>
    </ExternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      